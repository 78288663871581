import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';

import ClusterOtelMixin from '../common/cluster-otel.mixin';
import { LOGTYPE } from '../../constants';

/**
 * Mixin for rates cluster pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class RatesClusterOtel extends Mixins(ClusterOtelMixin) {
    @Inject(ClusterRatesServiceS) protected clusterRatesService!: ClusterRatesService;

    get payload() {
        const filterToBuild = ['provider', 'priceType', 'los', 'roomTypeId', 'mealTypeId', 'month', 'year'];
        const settingsUnion = {
            ...this.clusterRatesService.currentSettings,
            ...this.documentFiltersService.settings,
        } as Record<string, any>;

        const filterBy = filterToBuild.reduce((acc, key) => {
            acc[key] = settingsUnion[key];
            return acc;
        }, {} as any);

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(filterBy),
        };
    }
}
