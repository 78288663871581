
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import type Day from '@/modules/common/types/day.type';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import ITableHotelData from '@/modules/cluster/interfaces/table-hotel.interface';
import DayPricesColumn from '@/modules/cluster/components/rates/table/day-prices-column.vue';
import HotelsColumn from '@/modules/cluster/components/rates/table/hotels-column.vue';
import ClusterPagination from '../../_common/cluster-pagination.vue';
import Skeleton from '../../_common/hotels-list/skeleton.vue';

const SCROLL_SPEED = 35;

@Component({
    components: {
        HotelsColumn,
        DayPricesColumn,
        ClusterPagination,
        Skeleton,
        TogglePrices,
    },
})
export default class RatesClusterTable extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;

    // ========= Class fields ========== //

    private scrollLeft: boolean | any = false;
    private clickedHotel: number = -1;
    private hasScroll: boolean = false;
    private openIndex: number = 0;
    private isDragScrolling = false;

    firstDocLoaded = false;

    // ======= Lifecycle methods ======== //

    // ===== Class getters/settrers ===== //

    get hotelId() {
        return this.clickedHotel;
    }

    get indexTab() {
        return this.openIndex;
    }

    get isScrollVisible() {
        return this.hasScroll;
    }

    get isLoading() {
        const state = this.clusterService.isLoading;

        if (!state) {
            this.$nextTick().then(() => this.scrollToCurrentDay());
        }

        return state;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get month() {
        return this.documentFiltersService.month;
    }

    get year() {
        return this.documentFiltersService.year;
    }

    get hotels() {
        const { hotels } = this.clusterRatesService;

        if (!hotels) {
            return null;
        }

        return hotels
            .map(hotel => {
                const hotelName = hotel.hotelName
                || this.hotelsService.getHotelName(hotel.hotelId)
                || hotel.hotelId;

                return {
                    id: hotel.hotelId,
                    compsetId: hotel.compsetMain?.id || hotel.compsets?.[0]?.id || '',
                    name: hotelName,
                    isCompetitor: false,
                    newTotalScore: hotel.newTotalScore,
                    totalScore: hotel.totalScore,
                    ratesCompsetMain: hotel.compsetMain as RatesCompsetMainModel,
                    compsets: hotel.compsets,
                } as ITableHotelData;
            });
    }

    get currentDay() {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date();
        if (year === date.getFullYear() && month === date.getMonth()) {
            return date.getDate() - 1;
        }

        return -1;
    }

    get isRateAscendingSort() {
        return this.clusterRatesService.storeState.ratesSorting === -1;
    }

    get isHotelNameAscendingSort() {
        return this.clusterRatesService.storeState.hotelNameSorting === -1;
    }

    get isRateSortingActive() {
        return !!this.clusterRatesService.storeState.ratesSorting;
    }

    get isSearchQuery() {
        return !!this.clusterService.clusterHotelSearchQuery?.length;
    }

    // ======= Other methods ======== //

    // Scroll to current day as soon as first document is loaded
    scrollToCurrentDay() {
        const { prices } = this.$refs as { prices: HTMLDivElement };

        if (this.currentDay === -1) {
            prices.scrollLeft = 0;
            return;
        }

        const currentDayColumn = (this.$refs.columns as Vue[])[this.currentDay].$el as HTMLElement;

        const { width } = prices.getBoundingClientRect();
        const targetScroll = currentDayColumn.getBoundingClientRect().left - width / 2;

        prices.scrollLeft += targetScroll;

        this.handleScroll();
    }

    formatDate(value: (Date | null)) {
        if (!value) return '';
        const weekday = this.$tc(`dayShort.${value.getDay()}`);
        const formatedDay = this.$i18n.locale === 'en' ? weekday[0] : weekday;
        return [formatedDay, moment(value).format('DD/MM')].join('');
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isToday(day: Day) {
        return this.documentFiltersService.isCurrentDay(day);
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }

    isWholeHotelNoData(hotelId: number) {
        return this.clusterRatesService
            .isWholeHotelNoData(hotelId);
    }

    getPrices(day: Day) {
        if (!this.hotels) {
            return [];
        }
        return this.hotels.map(hotel => this.getPrice(hotel.id, day));
    }

    getPrice(hotelId: number, day: Day) {
        const priceData: IPriceData = {
            isNoData: false,
            isNa: false,
            isSoldOut: false,
            isOutOfRange: false,
            competitionPercent: null,
            price: null,
            currency: null,
            hotelId,
            compsetId: '',
        };

        const mainCompsetData = this.clusterService
            .getMainCompsetData<RatesCompsetMainModel>(hotelId);

        const hotelData = this.clusterService.getHotelBy({ hotelId });

        if (hotelData) {
            const compset = hotelData.compsets.find(c => c.type === COMPSET_TYPE.MEDIAN) || hotelData.compsets[0];

            if (compset) {
                priceData.compsetId = compset.id;
            }
        }

        if (!mainCompsetData) {
            priceData.isOutOfRange = true;
            return priceData;
        }

        if (this.clusterRatesService.isNoData(day, hotelId)) {
            priceData.isNoData = true;
            return priceData;
        }

        if (this.clusterRatesService.isNa(day, hotelId)) {
            priceData.isNa = true;
            return priceData;
        }

        if (this.clusterRatesService.isSoldOut(day, hotelId)) {
            priceData.isSoldOut = true;
            return priceData;
        }

        priceData.price = this.clusterRatesService
            .getPrice(day, hotelId);
        priceData.currency = this.ratesCommonService
            .currency(mainCompsetData);
        priceData.competitionPercent = this.clusterRatesService
            .getCompetitionPercent(day, hotelId);
        priceData.color = this.clusterRatesService
            .getColor(day, hotelId);
        priceData.compsetId = mainCompsetData.id;

        return priceData;
    }

    handleScrollMouseRight(e: MouseEvent) {
        if (e.button !== 0) {
            return;
        }
        this.moveLeft(SCROLL_SPEED);
    }

    handleScrollMouseLeft(e: MouseEvent) {
        if (e.button !== 0) {
            return;
        }
        this.moveLeft(-SCROLL_SPEED);
    }

    handleTouchRight() {
        this.moveLeft(SCROLL_SPEED);
    }

    handleTouchLeft() {
        this.moveLeft(-SCROLL_SPEED);
    }

    handleScroll() {
        (this.$refs['dates-line'] as HTMLElement).scroll({ left: (this.$refs.prices as HTMLElement).scrollLeft });
    }

    moveLeft(way: number) {
        const prices = this.$refs.prices as HTMLElement;
        if (!this.scrollLeft) {
            // eslint-disable-next-line no-return-assign
            this.scrollLeft = setInterval(() => (prices.scrollLeft += way), 30);
        }
    }

    handleStop() {
        clearInterval(this.scrollLeft);
        this.scrollLeft = false;
    }

    startDragScroll() {
        this.isDragScrolling = true;
        const moveHandler = (moveEvent: MouseEvent) => {
            const { prices } = this.$refs as { prices: HTMLDivElement };
            prices.scrollLeft -= moveEvent.movementX;
        };

        window.addEventListener('mousemove', moveHandler);
        window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', moveHandler);
            this.isDragScrolling = false;
        }, { once: true });
    }

    clickRowHandle(data: { hotelId: number; index: number }) {
        if (this.isDragScrolling) return;

        if (this.clickedHotel !== data.hotelId) {
            this.clusterService.getClusterHotels(data.hotelId);
        }
        this.clickedHotel = data.hotelId === this.hotelId ? -1 : data.hotelId;
        this.openIndex = data.index === this.indexTab ? 1 : data.index;
    }

    sort() {
        this.clusterRatesService.toggleScoreSort();
    }

    sortByABC() {
        this.clusterRatesService.toggleHotelNameSort();
    }
}
